import React from "react"
import MainLayout from 'src/layouts/Main'
import PageTitleHero from 'components/PageTitleHero'
import { navigate } from 'gatsby-link'

export default () => {

    // const handleSubmit = (e)=> {
    //     e.preventDefault()
    //     const form = e.target
    //     console.log('submitted');

    //     navigate(form.getAttribute('action'))
        
    // }
    return(
    <MainLayout>
        <PageTitleHero
            title="Contact <span>Me</span>"
            class="contact-hero"
        />
     
        <div className="flex-content-container">
          <div className="col-one">
            <h2>Thank you!</h2>
            <p>I will get back with you ASAP.</p>
            </div>
            
        </div>
    </MainLayout>
    )
}
